<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12">
        <v-card flat rounded="lg">
          <v-card-title class="mygenText--text font-weight-bold">
            サービスオプション設定
          </v-card-title>
          <v-card-text>
            <v-container class="pa-0">
              <v-row align="center">
                <v-col cols="12 mt-1 pb-0">
                  <h4
                    v-for="(comment, key) in forms.optionComment"
                    :key="'option-comment-' + key"
                    class="secondary--text"
                  >
                    {{ comment }}
                  </h4>
                </v-col>
                <v-col cols="12" class="px-0">
                  <v-btn-toggle
                    v-model="toggleOption"
                    group
                    multiple
                    color="mygenIcon"
                    active-class="primary"
                  >
                    <v-container>
                      <v-row v-if="isOptionsFree" align="center">
                        <v-col
                          v-for="option in optionsFree"
                          :key="option.id"
                          cols="12"
                          md="6"
                          class="align-self-start"
                        >
                          <v-btn
                            :value="option.id"
                            block
                            class="px-7 py-5"
                            :min-height="$vuetify.breakpoint.xs ? '90' : '75'"
                            @change="changeToggleBtn(option.id)"
                          >
                            <div>
                              <h4>{{ option.option_name }}</h4>
                              <span class="text-body-1">
                                {{ convertNumComma(option.option_fee) }}
                              </span>
                              <span class="text-body-2"> 円 </span>
                              <br v-if="$vuetify.breakpoint.xs" />
                              <span class="text-body-2"> （税込 </span>
                              <span class="text-body-1">
                                {{
                                  convertNumComma(
                                    option.option_fee_including_tax
                                  )
                                }}
                              </span>
                              <span class="text-body-2">円）</span>
                            </div>
                          </v-btn>
                          <FormControls
                            propsMode="post"
                            :propsForm="option.optionQuantity"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text>
            <v-container>
              <v-row>
                <v-spacer />
                <v-col cols="12" sm="6">
                  <ButtonCommon
                    propsLabel="サービスオプション登録"
                    :propsClick="saveData"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <OverlayProgress key="progress" v-if="progress" />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { numericIntegerOrBlankRules } from "@/utils/ruleUtility.js";
import { convertNumComma } from "@/utils/utilities.js";
import { defineComponent, computed, ref, reactive } from "vue";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";

export default defineComponent({
  name: "ContainerOptionSetting",

  components: { ButtonCommon, FormControls, OverlayProgress },

  props: {
    propsEditId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const editId = computed(() => {
      return props.propsEditId;
    });

    const forms = reactive({
      optionComment: [
        "サービス設定するオプションを選択してください。",
        "数量指定の必要なオプションは必要数を指定してください。",
      ],
    });

    const optionsFree = ref({});
    const isOptionsFree = computed(() => {
      return Object.keys(optionsFree.value).length > 0 ? true : false;
    });

    const toggleOption = ref([]);
    const progress = ref(false);

    const changeToggleBtn = (key) => {
      // トグルボタンアクション
      if (toggleOption.value.includes(key)) {
        optionsFree.value[key]["optionQuantity"]["disabled"] = true;
        optionsFree.value[key]["optionQuantity"]["value"] = "";
      } else {
        optionsFree.value[key]["optionQuantity"]["disabled"] = false;
      }
    };

    const loadData = () => {
      progress.value = true;
      // api:データ取得
      const apiAddress = "/contractoptionhandle/" + editId.value + "/";
      api({
        method: "get",
        url: apiAddress,
      })
        .then((response) => {
          const res = response.data;

          // 契約オプション設定
          optionsFree.value = {};
          const optionData = res["option_data"];
          const optionFields = res["option_fields"];
          const optionFreeFields = res["option_free_fields"];
          for (const key in optionData) {
            // 数量指定
            const optionQuantity = {
              display: optionData[key]["quantity_display"],
              type: optionData[key]["quantity_display"] === 1 ? "number" : "",
              value:
                key in optionFreeFields
                  ? optionFreeFields[key]["option_quantity"]
                  : "",
              label: optionData[key]["quantity_label"],
              rules:
                optionData[key]["quantity_display"] === 1
                  ? [numericIntegerOrBlankRules]
                  : [],
              min: 0,
              disabled: true,
            };
            optionData[key]["optionQuantity"] = optionQuantity;
            // オプション選択されていなければ選択肢として追加
            if (!(key in optionFields) || key in optionFreeFields) {
              optionsFree.value[key] = optionData[key];
              if (key in optionFreeFields) {
                // トグルボタン有効化
                toggleOption.value.push(key);
                // 数量指定有効化
                optionsFree.value[key]["optionQuantity"]["disabled"] = false;
              }
            }
          }

          forms["optionComment"] = forms["optionComment"].concat(res["on"]);
          progress.value = false;
        })
        .catch((reason) => {
          console.error(reason); // Error!
          progress.value = false;
        });
    };

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const optionsQtys = {};
      for (const key in optionsFree.value) {
        optionsQtys[key] = optionsFree.value[key]["optionQuantity"];
      }
      const data = {
        contract_plan_id: editId.value,
        option_id: toggleOption.value,
        option_quantitys: JSON.stringify(optionsQtys),
      };
      // 登録
      const apiAddress = "/contractoptionhandle/" + editId.value + "/";
      api({
        method: "post",
        url: apiAddress,
        data: data,
      }).then(
        () => {
          store.dispatch("message/setInfoMessage", {
            message: "サービスオプションを登録しました。",
          });
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // データ取得
    loadData();

    return {
      convertNumComma,

      forms,
      optionsFree,
      isOptionsFree,
      toggleOption,

      progress,

      changeToggleBtn,
      saveData,
    };
  },
});
</script>
