<template>
  <v-container width="100%">
    <v-row>
      <v-col>
        <v-card
          width="850"
          min-height="800"
          rounded="lg"
          flat
          class="mx-0 pa-5"
        >
          <v-card-title>
            <SpanHeadline :propsText="cardTitle" />
          </v-card-title>
          <v-card-text :class="$vuetify.breakpoint.xs ? 'px-0' : ''">
            <v-stepper v-model="stepCount" vertical flat>
              <!-- ステップ1 -->
              <v-stepper-step
                :complete="stepCount > 1"
                step="1"
                class="mygenText--text text-h6"
              >
                {{ stepFirstTitle }}
                <span v-if="stepCount > 1" class="primary--text text-h6">
                  <br />
                  <span class="mr-3">
                    {{ planName }}
                  </span>
                  {{ forms.accountQuantity.value }}user
                </span>
              </v-stepper-step>
              <v-stepper-content step="1">
                <v-form ref="formFirst" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <h1 class="primary--text mb-3">
                            {{ planName }}
                          </h1>
                          <h3 class="primary--text">
                            {{ forms.accountPrice }}
                          </h3>
                          <h3 class="primary--text">
                            {{ forms.accountQuantityBase }}
                          </h3>
                        </v-col>
                        <v-col cols="12" md="4">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.accountQuantity"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtn1"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ2 -->
              <v-stepper-step
                :complete="stepCount > 2"
                step="2"
                class="mygenText--text text-h6"
              >
                {{ stepSecondTitle }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-form ref="formSecond" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col>
                          <ContainerContractDetail
                            :key="'table-' + formKey"
                            :propsData="orderData"
                            :propsOrder="true"
                            :propsChange="true"
                            :propsShowCharge="true"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <CardTermsOfService
                            class="mt-5"
                            @download="downloadTos"
                          />
                          <v-checkbox
                            v-model="checkTermsOfService.value"
                            :label="checkTermsOfService.label"
                            :rules="checkTermsOfService.rules"
                            :disabled="!isTosDownload"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="確定"
                            :propsClick="submitBtn2"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ3 -->
              <v-stepper-step
                :complete="stepCount === 3"
                step="3"
                class="mygenText--text text-h6"
              >
                {{ stepThirdTitle }}
              </v-stepper-step>
              <v-stepper-content step="3">
                <v-container>
                  <v-row align="center">
                    <v-col>
                      <p class="font-weight-bold primary--text text-h6">
                        申込が完了しました
                      </p>
                      <p class="mygenText--text text-body-1">
                        申し込みの進捗は［契約中のサービス一覧］のステータスでご確認いただけます。
                      </p>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <ButtonCommon
                        propsLabel="閉じる"
                        :propsClick="clickClose"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
      <v-spacer />
    </v-row>

    <OverlayProgress v-if="progress" />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { convertNumComma, createKey } from "@/utils/utilities.js";
import {
  numericIntegerRules,
  requiredCheckboxRules,
} from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import CardTermsOfService from "@/components/atoms/CardTermsOfService.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";
import ContainerContractDetail from "@/components/molecules/ContainerContractDetail.vue";

export default defineComponent({
  name: "FormContractAccountOrder",

  components: {
    CardTermsOfService,
    FormControls,
    ButtonCommon,
    ContainerContractDetail,
    OverlayProgress,
    SpanHeadline,
  },

  props: {
    propsEditId: {
      type: String,
      required: true,
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const editId = ref(props.propsEditId);

    const cardTitle = ref("アカウント数変更");
    const stepFirstTitle = ref("アカウント数指定");
    const stepSecondTitle = ref("申込内容確認");
    const stepThirdTitle = ref("申込完了");
    const formFirst = ref(false);
    const formSecond = ref(false);

    const stepCount = ref(1);
    const contractFields = ref({});
    const plans = ref({});
    const options = ref({});
    const optionsDefault = ref({});
    const optionsAdd = ref({});
    const structure = ref({});
    const coupons = ref({});

    const planId = ref("");
    const planName = ref("");
    const toggleOption = ref([]);
    const forms = reactive({
      accountPrice: "",
      accountQuantityLabel: "",
      accountQuantityBase: "",
      accountQuantity: {
        type: "number",
        value: "",
        base: "",
        label: "変更アカウント数",
        rules: [numericIntegerRules],
        min: 1,
      },
      coupon: "クーポン利用",
      couponComment: "クーポンご利用の場合はクーポンコードを入力",
      couponCode: {
        type: "text",
        value: "",
        label: "クーポンコード",
        rules: [],
      },
      couponId: "",
      couponName: "",
      siteName: {
        type: "text",
        value: "自社用サイト",
        label: "サイト識別名",
        // rules: [charLength200Rules],
      },
    });
    const orderData = ref({});
    const changeFirst = ref(false);
    const checkTermsOfService = reactive({
      value: false,
      label: "利用規約に同意する（利用規約を確認するとチェックできます）",
      rules: [requiredCheckboxRules],
    });
    const isTosDownload = ref(false);
    const progress = ref(false);
    const formKey = ref(createKey());

    // オーダー情報ロード
    const loadOrderData = () => {
      const optionsQtys = {};
      for (const key in options.value) {
        optionsQtys[key] = options.value[key]["optionQuantity"];
      }
      // api:申込金額計算情報取得
      // 当初getを使用していたがGETパラメーターが長すぎるとnginxの設定を変更しなければいけないためPOSTで取得する
      const data = {
        order_type: 20,
        plan_id: planId.value,
        account_quantity: forms.accountQuantity.value,
        option_id: toggleOption.value,
        option_quantitys: JSON.stringify(optionsQtys),
        coin_quantity: 0,
        coupon_code: forms.couponCode.value,
        base_contract_plan_id: editId.value,
        site_name: forms.siteName.value,
      };
      api({
        method: "post",
        url: "/ordercalc/",
        data: data,
      }).then((response) => {
        const res = response.data;
        orderData.value = res;
        // 次へボタンクリックが早いとapiデータの更新が追いつかない
        // データが更新された時点で次画面に進むため、変更検知フラグをここで設定している
        if (forms.accountQuantity.base === forms.accountQuantity.value) {
          changeFirst.value = false;
        } else {
          changeFirst.value = true;
        }
      });
    };

    // 既存データロード
    const loadBaseData = () => {
      // api:データ取得
      api({
        method: "get",
        url: "/contractplanbase/?id=" + editId.value,
      })
        .then((response) => {
          const res = response.data[0];
          contractFields.value = res["contract"];
          planId.value = res["plan"];

          const data = plans.value[planId.value];
          forms.accountPrice =
            "1user @" +
            data["account_price"] +
            "円（税込" +
            convertNumComma(data["account_price_including_tax"]) +
            "円）";
          planName.value = data["plan_name"];

          // アカウント数
          forms.accountQuantity.value = res["account_quantity"];
          forms.accountQuantity.base = res["account_quantity"];
          forms.accountQuantityBase =
            "現在契約アカウント x" +
            convertNumComma(res["account_quantity"]) +
            "user";

          // クーポン設定
          if (res["coupon_class"] === -20) {
            forms.couponCode.value = res["coupon_code"];
          }

          // サイト名
          forms.siteName.value = res["contract"]["site_name"];

          // オプション設定
          toggleOption.value = []; // オプション選択の初期化
          options.valueDefault = {};
          options.valueAdd = {};
          const tempStructure = structure.value[planId.value];
          for (const key in options.value) {
            for (const tempKey in tempStructure) {
              if (
                tempStructure[tempKey]["option_id"] === key &&
                tempStructure[tempKey]["structure_class"] === 10
              ) {
                options.valueDefault[key] = options.value[key];
              } else if (
                tempStructure[tempKey]["option_id"] === key &&
                tempStructure[tempKey]["structure_class"] === 20
              ) {
                options.valueAdd[key] = options.value[key];
              }
            }
          }

          // 標準オプション
          const baseOption = [];
          for (const key in res["contract_option"]) {
            const tempOption = res["contract_option"][key];
            baseOption.push(tempOption["option"]);
            if (
              options.value[tempOption["option"]]["optionQuantity"][
                "display"
              ] === 1
            ) {
              options.value[tempOption["option"]]["optionQuantity"]["value"] =
                tempOption["option_quantity"];
            }
          }
          toggleOption.value = baseOption;
        })
        .then(() => {
          // オーダー情報ロード
          loadOrderData();
        });
    };

    /**
     * 情報取得
     */
    const loadData = () => {
      // api:設定情報取得
      api({
        method: "get",
        url: "/ordersetting/",
      })
        .then((response) => {
          const res = response.data["order_setting_data"];
          // 契約プラン設定
          plans.value = res["plan_data"];
          // 契約オプション設定
          options.value = res["option_data"];
          for (const key in options.value) {
            const optionQuantity = {
              display: options.value[key]["quantity_display"],
              type:
                options.value[key]["quantity_display"] === 1 ? "number" : "",
              value: "",
              label: options.value[key]["quantity_label"],
            };
            options.value[key]["optionQuantity"] = optionQuantity;
          }
          // 構成設定
          structure.value = res["structure_data"];
          // クーポン設定
          coupons.value = res["coupon_data"];
        })
        .then(() => {
          // 既存データロード
          loadBaseData();
        });
    };

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const optionsQtys = {};
      for (const key in options.value) {
        optionsQtys[key] = options.value[key]["optionQuantity"];
      }

      const date = new Date();
      const nextDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      const startDate =
        nextDate.getFullYear() + "-" + (nextDate.getMonth() + 1) + "-1";

      const data = {
        order_type: 20,
        customer_id: store.state.auth.customerId,
        contract_fields: contractFields.value,
        plan_id: planId.value,
        account_quantity: forms.accountQuantity.value,
        option_id: toggleOption.value,
        option_quantitys: JSON.stringify(optionsQtys),
        coin_quantity: 0,
        coupon_code: forms.couponCode.value,
        base_contract_plan_id: editId.value,
        start_date: startDate,
        site_name: forms.siteName.value,
      };
      // 登録
      api({
        method: "post",
        url: "/contractplanbase/",
        data: data,
      }).then(
        () => {
          store.dispatch("message/setInfoMessage", {
            message: "契約の申込が完了しました。",
          });
          stepCount.value++;
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // 利用規約ダウンロード
    const downloadTos = () => {
      isTosDownload.value = true;
    };

    // ボタン1
    const submitBtn1 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult && changeFirst.value) {
        store.dispatch("message/clearMessages");
        formKey.value = createKey();
        stepCount.value++;
      } else if (!changeFirst.value) {
        const message = "アカウント数が変更されていません";
        store.dispatch("message/setErrorMessage", {
          message: message,
        });
        return false;
      }
    };
    // ボタン2
    const submitBtn2 = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formSecond.value.validate();
      if (validResult) {
        store.dispatch("message/clearMessages");
        saveData();
      }
    };
    // 戻るボタン
    const returnBtn = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };
    // 閉じるボタン
    const clickClose = () => {
      // ダイアログ
      emit("close");
    };

    onMounted(() => {
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      editId,

      cardTitle,
      stepFirstTitle,
      stepSecondTitle,
      stepThirdTitle,
      formFirst,
      formSecond,

      stepCount,
      contractFields,
      plans,
      options,
      optionsDefault,
      optionsAdd,
      structure,
      coupons,

      planId,
      planName,
      toggleOption,
      forms,
      orderData,
      changeFirst,
      checkTermsOfService,
      isTosDownload,
      progress,
      formKey,

      loadOrderData,
      downloadTos,
      submitBtn1,
      submitBtn2,
      returnBtn,
      clickClose,
    };
  },
});
</script>
