import store from "@/store";
import api from "@/services/api";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

/**
 * タイムスタンプ取得
 */
const getTimestamp = (isNonFormat = false) => {
  const date = new Date();
  const yyyy = `${date.getFullYear()}`;
  // .slice(-2)で文字列中の末尾の2文字を取得する
  // `0${date.getHoge()}`.slice(-2) と書くことで０埋めをする
  const MM = `0${date.getMonth() + 1}`.slice(-2); // getMonth()の返り値は0が基点
  const dd = `0${date.getDate()}`.slice(-2);
  const HH = `0${date.getHours()}`.slice(-2);
  const mm = `0${date.getMinutes()}`.slice(-2);
  const ss = `0${date.getSeconds()}`.slice(-2);

  if (isNonFormat) {
    return `${yyyy}${MM}${dd}${HH}${mm}${ss}`;
  } else {
    return `${yyyy}/${MM}/${dd} ${HH}:${mm}:${ss}`;
  }
};

/**
 * Excel出力：SheetをWorkbookに追加する
 * 参照：https://github.com/SheetJS/js-xlsx/issues/163
 */
const sheet_to_workbook = (sheet, opts) => {
  const n = opts && opts.sheet ? opts.sheet : "Sheet1";
  const sheets = {};
  sheets[n] = sheet;
  return { SheetNames: [n], Sheets: sheets };
};

/**
 * Excel出力：ArrayをWorkbookに変換する
 * 参照：https://github.com/SheetJS/js-xlsx/issues/163
 */
const aoa_to_workbook = (data, opts) => {
  return sheet_to_workbook(XLSX.utils.aoa_to_sheet(data, opts), opts);
};

/**
 * Excel出力：stringをArrayBufferに変換する
 * 参照：https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
 */
const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

/**
 * Excel出力
 */
const excelExport = async (fileName, fileData, fileOption) => {
  const write_opts = {
    type: "binary",
  };

  // ArrayをWorkbookに変換する
  const wb = aoa_to_workbook(fileData);

  // index指定でシート名を取得
  const wsName = wb.SheetNames[0];
  // シート名からworksheetを取得
  const ws = wb.Sheets[wsName];
  /* column props */
  ws["!cols"] = fileOption;

  const wb_out = XLSX.write(wb, write_opts);

  // WorkbookからBlobオブジェクトを生成
  // 参照：https://developer.mozilla.org/ja/docs/Web/API/Blob
  const blob = new Blob([s2ab(wb_out)], {
    type: "application/octet-stream",
  });

  const tempName = fileName + "_" + getTimestamp(true) + ".xlsx";

  // FileSaverのsaveAs関数で、xlsxファイルとしてダウンロード
  // 参照：https://github.com/eligrey/FileSaver.js/
  saveAs(blob, tempName);
};

/**
 * リストExcel出力
 */
const exportListExcel = (formObject, items) => {
  const excelHeader = [];
  const excelColumn = [];
  const excelOption = [];
  for (const key in formObject.headers) {
    const lineDict = formObject.headers[key];
    if (lineDict["nonExcel"]) continue;
    // ヘッダー取得
    excelHeader.push(lineDict["text"]);
    // カラム取得
    excelColumn.push(lineDict["value"]);
    // wpx：列幅を指定
    if (lineDict["wpx"] && Number.isInteger(lineDict["wpx"])) {
      excelOption.push({
        wpx: lineDict["wpx"],
      });
    } else {
      excelOption.push({
        wpx: 100,
      });
    }
  }
  if (excelHeader.length !== 0) {
    // データ取得
    const excelData = items.value.map((item) => {
      const line = [];
      for (const key of excelColumn) {
        line.push(item[key]);
      }
      return line;
    });
    // 先頭にヘッダーを追加
    excelData.unshift(excelHeader);
    // エクセル出力
    excelExport("list", excelData, excelOption);
  }
};

/**
 * お客様リストデータExcel出力
 */
const exportCustomerExcel = (formObject) => {
  if (!formObject.excelCustomerFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelCustomerFormat) {
    excelHeader.push(formObject.excelCustomerFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelCustomerFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/customerexport/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("cul", res, option);
  });
  return;
};

/**
 * 契約リストデータExcel出力
 */
const exportContractExcel = (formObject) => {
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelContractFormat) {
    excelHeader.push(formObject.excelContractFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelContractFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/contractexport/",
  }).then((response) => {
    const res = response.data;

    // データループ
    let casheCode = "";
    let maxCount = 0;
    const dataDict = {};
    const dataArray = [];
    for (const key in res) {
      if (String(res[key][0]) === String(casheCode)) {
        // 追加オプションはデータ追加
        if (res[key][10] === false) {
          dataDict[casheCode].push((res[key][11] ? "* " : "") + res[key][9]);
        }
      } else {
        casheCode = res[key][0];
        // 契約プランを追加
        dataDict[casheCode] = [
          res[key][1],
          res[key][2],
          res[key][3],
          res[key][4],
          res[key][5],
          res[key][6],
          res[key][7],
          res[key][8],
        ];
        // 追加オプションはデータ追加
        if (res[key][10] === false) {
          dataDict[casheCode].push((res[key][11] ? "* " : "") + res[key][9]);
        }
      }
    }
    // 出力データ生成
    for (const key in dataDict) {
      const count = dataDict[key].length;
      if (maxCount < count) {
        maxCount = count;
      }
      dataArray.push(dataDict[key]);
    }
    // ヘッダー追加
    for (let i = 8; i < maxCount; i++) {
      excelHeader.push("追加オプション" + (i - 6));
      // wpx：列幅を指定
      option.push({ wpx: 100 });
    }
    dataArray.unshift(excelHeader);

    // エクセル出力
    excelExport("col", dataArray, option);
  });
  return;
};

/**
 * 自動口座振替データExcel出力
 */
const exportAutomaticTransferExcel = (formObject, group) => {
  if (group.length === 0) return;
  if (!formObject.excelAutomaticTransferFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelAutomaticTransferFormat) {
    excelHeader.push(formObject.excelAutomaticTransferFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelAutomaticTransferFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/invoiceautomatictransferexport/" + group + "/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("at", res, option);
  });
  return;
};

/**
 * コイン管理データExcel出力
 */
const exportManagementCoinExcel = (formObject) => {
  if (!formObject.excelManagementCoinFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelManagementCoinFormat) {
    excelHeader.push(formObject.excelManagementCoinFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelManagementCoinFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/managementcoinexport/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("mc", res, option);
  });
  return;
};

/**
 * コイン管理明細データExcel出力
 */
const exportManagementCoinDetailExcel = (formObject) => {
  if (!formObject.excelManagementCoinDetailFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelManagementCoinDetailFormat) {
    excelHeader.push(formObject.excelManagementCoinDetailFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelManagementCoinDetailFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/managementcoindetailexport/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("mcd", res, option);
  });
  return;
};

/**
 * 移行確認用Excel出力
 */
const exportTransferCheckExcel = (formObject) => {
  if (!formObject.excelTransferCheckFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelTransferCheckFormat) {
    excelHeader.push(formObject.excelTransferCheckFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelTransferCheckFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/transfercheckexcel/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("tc", res, option);
  });
  return;
};

/**
 * 請求明細データExcel出力（全件）
 */
const exportInvoiceDetailAllExcel = (formObject, group) => {
  if (group.length === 0) return;
  if (!formObject.isInvoiceDetailAllExcelExport) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.isInvoiceDetailAllExcelExport) {
    excelHeader.push(formObject.isInvoiceDetailAllExcelExport[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.isInvoiceDetailAllExcelExport[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/invoicedetailallexport/" + group + "/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("ida", res, option);
  });
  return;
};

/**
 * 支払データExcel出力
 */
const exportPaymentUserExcel = (formObject, header) => {
  if (header.length === 0) return;
  if (!formObject.excelPaymentUserFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelPaymentUserFormat) {
    excelHeader.push(formObject.excelPaymentUserFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelPaymentUserFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/paymentuserexport/" + header + "/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("ptu", res, option);
  });
  return;
};

/**
 * 支払ユーザー集計データExcel出力（全件）
 */
const exportPaymentUserAllExcel = (formObject, group) => {
  if (group.length === 0) return;
  if (!formObject.excelPaymentUserAllFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelPaymentUserAllFormat) {
    excelHeader.push(formObject.excelPaymentUserAllFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelPaymentUserAllFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/paymentuserallexport/" + group + "/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("pua", res, option);
  });
  return;
};

/**
 * 支払明細データExcel出力（全件）
 */
const exportPaymentDetailAllExcel = (formObject, group) => {
  if (group.length === 0) return;
  if (!formObject.excelPaymentDetailAllFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelPaymentDetailAllFormat) {
    excelHeader.push(formObject.excelPaymentDetailAllFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelPaymentDetailAllFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/paymentdetailallexport/" + group + "/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("pda", res, option);
  });
  return;
};

/**
 * 支払明細データExcel出力
 */
const exportPaymentDetailExcel = (formObject, header) => {
  if (header.length === 0) return;
  if (!formObject.excelPaymentDetailFormat) return;
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelPaymentDetailFormat) {
    excelHeader.push(formObject.excelPaymentDetailFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelPaymentDetailFormat[key]["wpx"],
    });
  }
  api({
    method: "get",
    url: "/paymentdetailexport/" + header + "/",
  }).then((response) => {
    const res = response.data;
    // 先頭にヘッダーを追加
    res.unshift(excelHeader);
    // エクセル出力
    excelExport("ptd", res, option);
  });
  return;
};

/**
 * 請求書確認用Excel出力
 */
const exportInvoiceCheckExcel = (formObject, from, to) => {
  const excelHeader = [];
  const option = [];
  for (const key in formObject.excelDifferenceCheckFormat) {
    excelHeader.push(formObject.excelDifferenceCheckFormat[key]["label"]);
    // wpx：列幅を指定
    option.push({
      wpx: formObject.excelDifferenceCheckFormat[key]["wpx"],
    });
  }

  // データ取得
  api({
    method: "get",
    url: "/invoicegrouplist/",
  })
    .then((response) => {
      const invoicegrouplist = response.data;
      if (!invoicegrouplist.includes(from)) {
        store.dispatch("message/setErrorMessage", {
          message: "Invoice Group1 の指定が正しくありません。",
        });
        return false;
      } else if (!invoicegrouplist.includes(to)) {
        store.dispatch("message/setErrorMessage", {
          message: "Invoice Group2 の指定が正しくありません。",
        });
        return false;
      }

      // 請求データ比較
      const address = "/invoicedifference/" + from + "/" + to + "/";
      api({
        method: "get",
        url: address,
      }).then((response) => {
        const res = response.data;
        // 先頭にヘッダーを追加
        res.unshift(excelHeader);
        // エクセル出力
        excelExport("diff", res, option);
      });
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return true;
};

export {
  exportListExcel,
  exportCustomerExcel,
  exportContractExcel,
  exportAutomaticTransferExcel,
  exportManagementCoinExcel,
  exportManagementCoinDetailExcel,
  exportTransferCheckExcel,
  exportInvoiceDetailAllExcel,
  exportPaymentUserExcel,
  exportPaymentUserAllExcel,
  exportPaymentDetailAllExcel,
  exportPaymentDetailExcel,
  exportInvoiceCheckExcel,
};
