<template>
  <v-container width="100%">
    <v-row>
      <v-col>
        <v-card
          width="850"
          min-height="800"
          rounded="lg"
          flat
          class="mx-0 pa-5"
        >
          <v-card-title>
            <SpanHeadline :propsText="cardTitle" />
          </v-card-title>
          <v-card-text :class="$vuetify.breakpoint.xs ? 'px-0' : ''">
            <v-stepper v-model="stepCount" vertical flat>
              <!-- ステップ1 -->
              <v-stepper-step
                :complete="stepCount > stepFirstValue"
                :step="stepFirstValue"
                class="mygenText--text text-h6"
              >
                {{ stepFirstTitle }}
                <span
                  v-if="stepCount > stepFirstValue"
                  class="primary--text text-h6"
                >
                  <br />
                  <span class="mr-3">
                    {{ togglePlanSelect }}
                  </span>
                </span>
              </v-stepper-step>
              <v-stepper-content :step="stepFirstValue">
                <v-form ref="formFirst" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col cols="12" class="pb-0">
                          <h4
                            v-for="(comment, key) in forms.planComment"
                            :key="'plan-comment-' + key"
                            class="secondary--text"
                          >
                            {{ comment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" class="px-0">
                          <v-btn-toggle
                            v-model="togglePlan"
                            mandatory
                            group
                            color="mygenIcon"
                            active-class="primary"
                            @change="changePlan(), loadOrderData()"
                          >
                            <v-container>
                              <v-row align="center">
                                <v-col
                                  v-for="plan in plans"
                                  :key="plan.id"
                                  cols="12"
                                  md="6"
                                >
                                  <v-btn
                                    :value="plan.id"
                                    block
                                    :class="
                                      $vuetify.breakpoint.xs ? 'px-0' : 'px-10'
                                    "
                                    :min-height="
                                      $vuetify.breakpoint.xs ? '90' : '75'
                                    "
                                  >
                                    <div>
                                      <h2>{{ plan.plan_name }}</h2>
                                      <span class="text-body-1">
                                        {{ convertNumComma(plan.basic_charge) }}
                                      </span>
                                      <span class="text-body-2"> 円 </span>
                                      <br v-if="$vuetify.breakpoint.xs" />
                                      <span class="text-body-2"> （税込 </span>
                                      <span class="text-body-1">
                                        {{
                                          convertNumComma(
                                            plan.basic_charge_including_tax
                                          )
                                        }}
                                      </span>
                                      <span class="text-body-2"> 円） </span>
                                    </div>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtnFirst"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ2 -->
              <v-stepper-step
                :complete="stepCount > stepSecondValue"
                :step="stepSecondValue"
                class="mygenText--text text-h6"
              >
                {{ stepSecondTitle }}
                <span
                  v-if="stepCount > stepSecondValue"
                  class="primary--text text-h6"
                >
                  <br />
                  <span class="mr-3">
                    {{ forms.accountQuantity.value }} user
                  </span>
                </span>
              </v-stepper-step>
              <v-stepper-content :step="stepSecondValue">
                <v-form ref="formSecond" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <h3 class="primary--text">
                            {{ forms.accountPrice }}
                          </h3>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                          <h4
                            v-for="(comment, key) in forms.accountPriceComment"
                            :key="'accountprice-comment-' + key"
                            class="secondary--text"
                          >
                            {{ comment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.accountQuantity"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="isChange">
                        <v-col cols="12" class="pb-0">
                          <h4
                            v-for="(comment, key) in forms.accountChangeComment"
                            :key="'accountchange-comment-' + key"
                            class="secondary--text"
                          >
                            {{ comment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.accountOrderComment"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtnSecond"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ3 -->
              <v-stepper-step
                :complete="stepCount > stepThirdValue"
                :step="stepThirdValue"
                class="mygenText--text text-h6"
              >
                {{ stepThirdTitle }}
                <div v-if="stepCount > stepThirdValue" class="mt-1">
                  <span
                    v-for="(option, key) in optionsDefault"
                    :key="'span-default-' + key"
                  >
                    <ChipOption
                      :propsClass="50"
                      :propsIsSmall="true"
                      :propsText="option.option_name"
                    />
                  </span>
                  <span
                    v-for="(option, key) in getToggleOptionData()"
                    :key="'span-option-' + key"
                  >
                    <ChipOption
                      :propsClass="60"
                      :propsIsSmall="true"
                      :propsText="option.option_name"
                    />
                  </span>
                </div>
              </v-stepper-step>
              <v-stepper-content :step="stepThirdValue">
                <v-form ref="formThird" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col class="mygenText--text text-h6 pb-0">
                          標準オプション
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col>
                          <span
                            v-for="(option, key) in optionsDefault"
                            :key="'span-' + key"
                          >
                            <ChipOption
                              :propsClass="50"
                              :propsText="option.option_name"
                            />
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col cols="12 mt-5 pb-0">
                          <h4
                            v-for="(comment, key) in forms.optionComment"
                            :key="'option-comment-' + key"
                            class="secondary--text"
                          >
                            {{ comment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" class="px-0">
                          <v-btn-toggle
                            v-model="toggleOption"
                            group
                            multiple
                            color="mygenIcon"
                            active-class="primary"
                            @change="loadOrderData"
                          >
                            <v-container>
                              <v-row v-if="isOptionsAccountAdd" align="center">
                                <v-col class="mygenText--text text-h6 pb-0">
                                  アカウント追加オプション
                                </v-col>
                              </v-row>
                              <v-row v-if="isOptionsAccountAdd" align="center">
                                <v-col
                                  v-for="option in optionsAccountAdd"
                                  :key="option.id"
                                  cols="12"
                                  md="6"
                                  class="align-self-start"
                                >
                                  <v-btn
                                    :value="option.id"
                                    block
                                    class="px-7 py-5"
                                    :min-height="
                                      $vuetify.breakpoint.xs ? '90' : '75'
                                    "
                                    @change="changeToggleBtn(option.id)"
                                  >
                                    <div>
                                      <h4>{{ option.option_name }}</h4>
                                      <span class="text-body-1">
                                        {{ convertNumComma(option.option_fee) }}
                                      </span>
                                      <span class="text-body-2"> 円 </span>
                                      <br v-if="$vuetify.breakpoint.xs" />
                                      <span class="text-body-2"> （税込 </span>
                                      <span class="text-body-1">
                                        {{
                                          convertNumComma(
                                            option.option_fee_including_tax
                                          )
                                        }}
                                      </span>
                                      <span class="text-body-2">円）</span>
                                    </div>
                                  </v-btn>
                                  <FormControls
                                    propsMode="post"
                                    :propsForm="option.optionQuantity"
                                    @controlChange="loadOrderData"
                                  />
                                </v-col>
                              </v-row>
                              <v-row v-if="isOptionsAccountAdd && isChange">
                                <v-col cols="12" class="pb-0">
                                  <h4
                                    v-for="(
                                      comment, key
                                    ) in forms.optionChangeComment"
                                    :key="'optionchange-comment-' + key"
                                    class="secondary--text"
                                  >
                                    {{ comment }}
                                  </h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <FormControls
                                    propsMode="post"
                                    :propsForm="forms.optionOrderComment"
                                    @controlChange="loadOrderData"
                                  />
                                </v-col>
                              </v-row>
                              <v-row v-if="isOptionsNormalAdd" align="center">
                                <v-col
                                  class="mygenText--text text-h6 pt-5 pb-0"
                                >
                                  追加オプション
                                </v-col>
                              </v-row>
                              <v-row v-if="isOptionsNormalAdd" align="center">
                                <v-col
                                  v-for="option in optionsNormalAdd"
                                  :key="option.id"
                                  cols="12"
                                  md="6"
                                  class="align-self-start"
                                >
                                  <v-btn
                                    :value="option.id"
                                    block
                                    class="px-7 py-5"
                                    :min-height="
                                      $vuetify.breakpoint.xs ? '90' : '75'
                                    "
                                    @change="changeToggleBtn(option.id)"
                                  >
                                    <div>
                                      <h4>{{ option.option_name }}</h4>
                                      <span class="text-body-1">
                                        {{ convertNumComma(option.option_fee) }}
                                      </span>
                                      <span class="text-body-2"> 円 </span>
                                      <br v-if="$vuetify.breakpoint.xs" />
                                      <span class="text-body-2"> （税込 </span>
                                      <span class="text-body-1">
                                        {{
                                          convertNumComma(
                                            option.option_fee_including_tax
                                          )
                                        }}
                                      </span>
                                      <span class="text-body-2">円）</span>
                                    </div>
                                  </v-btn>
                                  <FormControls
                                    propsMode="post"
                                    :propsForm="option.optionQuantity"
                                    @controlChange="loadOrderData"
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtnThird"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ4 -->
              <v-stepper-step
                v-if="isStepFourth"
                :complete="stepCount > stepFourthValue"
                :step="stepFourthValue"
                class="mygenText--text text-h6"
              >
                {{ stepFourthTitle }}
                <span v-if="stepCount > 4" class="primary--text text-h6">
                  <br />
                  {{ stepFourthComment }}<br />
                  <span v-if="!isEstimate">
                    {{ stepFourthSiteName }}<br />
                  </span>
                  <span v-if="isEstimate">
                    {{ stepFourthEstimateName }}<br />
                  </span>
                  <span v-if="isEstimate">
                    {{ stepFourthEstimateAddress }}
                  </span>
                </span>
              </v-stepper-step>
              <v-stepper-content v-if="isStepFourth" :step="stepFourthValue">
                <v-form ref="formFourth" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col cols="12">
                          <h3 class="primary--text mb-1">
                            {{ forms.coinRate }}
                          </h3>
                          <h4 class="secondary--text">
                            {{ forms.coinRateComment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.coinQuantity"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container>
                      <v-row align="center">
                        <v-col cols="12">
                          <h3 class="primary--text mb-1">
                            {{ forms.coupon }}
                          </h3>
                          <h4 class="secondary--text">
                            {{ forms.couponComment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.couponCode"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-if="!isEstimate">
                      <v-row align="center">
                        <v-col cols="12">
                          <h3 class="primary--text mb-1">
                            {{ forms.siteNameTitle }}
                          </h3>
                          <h4
                            v-for="(comment, key) in forms.siteNameComment"
                            :key="'startdate-comment-' + key"
                            class="secondary--text"
                          >
                            {{ comment }}
                          </h4>
                        </v-col>
                        <v-col cols="12" md="6">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.siteName"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-if="isEstimate">
                      <v-row align="center">
                        <v-col cols="12">
                          <h3 class="primary--text mb-1">
                            {{ forms.estimateTitle }}
                          </h3>
                          <h4
                            v-for="(comment, key) in forms.estimateComment"
                            :key="'startdate-comment-' + key"
                            class="secondary--text"
                          >
                            {{ comment }}
                          </h4>
                        </v-col>
                        <v-col cols="12">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.estimateName"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                        <v-col cols="12">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.estimateAddress"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="12">
                          <h3 class="primary--text mb-1">
                            {{ forms.estimateRemarksTitle }}
                          </h3>
                        </v-col>
                        <v-col cols="12">
                          <FormControls
                            propsMode="post"
                            :propsForm="forms.estimateRemarks"
                            @controlChange="loadOrderData"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="次へ"
                            :propsClick="submitBtnFourth"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ5 -->
              <v-stepper-step
                :complete="stepCount > stepFifthValue"
                :step="stepFifthValue"
                class="mygenText--text text-h6"
              >
                {{ stepFifthTitle }}
              </v-stepper-step>
              <v-stepper-content :step="stepFifthValue">
                <v-form ref="formFifth" @submit.prevent>
                  <v-card flat>
                    <v-container>
                      <v-row align="center">
                        <v-col>
                          <ContainerContractDetail
                            :key="'table-' + formKey"
                            :propsData="orderData"
                            :propsOrder="true"
                            :propsChange="isChange"
                            :propsShowCharge="true"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="forms.accountOrderComment.value.length > 0">
                        <v-col class="py-0">
                          <ContainerOrderComment
                            :key="'comment-account-' + formKey"
                            :propsComment="forms.accountOrderComment.value"
                            :propsIsOption="false"
                            :propsIsCard="false"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="forms.optionOrderComment.value.length > 0">
                        <v-col class="py-0">
                          <ContainerOrderComment
                            :key="'comment-option-' + formKey"
                            :propsComment="forms.optionOrderComment.value"
                            :propsIsOption="true"
                            :propsIsCard="false"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container v-if="!isEstimate">
                      <v-row>
                        <v-col cols="12">
                          <CardTermsOfService
                            class="mt-5"
                            @download="downloadTos"
                          />
                          <v-checkbox
                            v-model="checkPurchaseTerms.value"
                            :label="checkPurchaseTerms.label"
                            :rules="checkPurchaseTerms.rules"
                            :disabled="!isTosDownload"
                          />
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container v-if="isEstimate">
                      <v-row>
                        <v-col cols="12" sm="4">
                          <router-link
                            :to="{
                              name: 'reportEstimate',
                              params: {
                                key: localStorageKey,
                              },
                            }"
                            class="text-decoration-none"
                            target="_blank"
                          >
                            <ButtonCommon
                              propsLabel="印刷"
                              :propsClick="clickReport"
                            />
                          </router-link>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsLabel="確定"
                            :propsClick="submitBtnFifth"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="mygenIcon"
                            propsLabel="戻る"
                            :propsClick="returnBtn"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <ButtonCommon
                            propsColor="secondary"
                            propsLabel="閉じる"
                            :propsOutlined="true"
                            :propsClick="clickClose"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <!-- ステップ6 -->
              <v-stepper-step
                v-if="isStepSixth"
                :complete="stepCount === stepSixthValue"
                :step="stepSixthValue"
                class="mygenText--text text-h6"
              >
                {{ stepSixthTitle }}
              </v-stepper-step>
              <v-stepper-content :step="stepSixthValue" v-if="isStepSixth">
                <v-container>
                  <v-row align="center">
                    <v-col>
                      <p class="font-weight-bold primary--text text-h6">
                        申込が完了しました
                      </p>
                      <p class="mygenText--text text-body-1">
                        申し込みの進捗は［契約中のサービス一覧］のステータスでご確認いただけます。
                      </p>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <ButtonCommon
                        propsLabel="閉じる"
                        :propsClick="clickClose"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="
          !(
            $vuetify.breakpoint.xs ||
            $vuetify.breakpoint.sm ||
            $vuetify.breakpoint.md
          )
        "
      >
        <v-card
          color="mygenBase"
          class="mt-5 px-3 pb-3"
          dark
          elevation="19"
          width="220"
          style="position: fixed"
        >
          <v-card-text
            class="text-center font-weight-bold mygenText--text pa-2"
          >
            基本料金
          </v-card-text>
          <div v-for="total in totals" :key="total.label">
            <v-card
              v-if="
                total.isShow === true &&
                !(total.isCheckZero === true && total.value === 0)
              "
              :color="total.isDark === true ? 'primary' : 'mygenIcon'"
              dark
              flat
              rounded="0"
            >
              <v-card-text class="text-center text-caption px-3 py-1">
                <span
                  :class="
                    total.isDark === true
                      ? 'font-weight-bold white--text'
                      : 'font-weight-bold mygenText--text'
                  "
                >
                  {{ total.label }}
                </span>
              </v-card-text>
            </v-card>
            <v-card
              v-if="
                total.isShow === true &&
                !(total.isCheckZero === true && total.value === 0)
              "
              color="mygenCard"
              flat
              rounded="0"
            >
              <v-card-text
                :class="
                  total.isDark === true
                    ? 'font-weight-bold primary--text px-3 py-4 text-right'
                    : 'font-weight-bold secondary--text px-3 py-4 text-right'
                "
              >
                <span class="text-h6">
                  {{ convertNumComma(total.value) }}
                </span>
                <span class="text-body-2">円</span>
                <div v-if="total.includingTaxValue">
                  <span class="text-body-2">（</span>
                  <span class="text-body-2">税込</span>
                  <span class="text-body-1 font-weight-bold">
                    {{ convertNumComma(total.includingTaxValue) }}
                  </span>
                  <span class="text-body-2">円</span>
                  <span class="text-body-2">）</span>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
      <v-spacer />
      <v-spacer />
    </v-row>

    <OverlayProgress key="progress" v-if="progress" />
    <OverlayProgress
      key="progress-ordercald"
      v-if="progressOrderCalc"
      :propsIsTransparent="true"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router/composables";
import {
  convertNumComma,
  createKey,
  checkRequired,
} from "@/utils/utilities.js";
import {
  requiredRules,
  requiredCheckboxRules,
  numericIntegerRules,
  numericIntegerOrZeroRules,
  numericIntegerOrBlankRules,
  charLength200Rules,
} from "@/utils/ruleUtility.js";
import ButtonCommon from "@/components/atoms/ButtonCommon.vue";
import CardTermsOfService from "@/components/atoms/CardTermsOfService.vue";
import ChipOption from "@/components/atoms/ChipOption.vue";
import ContainerOrderComment from "@/components/atoms/ContainerOrderComment.vue";
import FormControls from "@/components/atoms/FormControls.vue";
import OverlayProgress from "@/components/atoms/OverlayProgress.vue";
import SpanHeadline from "@/components/atoms/SpanHeadline.vue";
import ContainerContractDetail from "@/components/molecules/ContainerContractDetail.vue";

export default defineComponent({
  name: "FormContractOrder",

  components: {
    CardTermsOfService,
    FormControls,
    ButtonCommon,
    ChipOption,
    ContainerOrderComment,
    ContainerContractDetail,
    OverlayProgress,
    SpanHeadline,
  },

  props: {
    propsFormMode: {
      type: String,
      required: true,
      default: "create",
    },
    propsEditId: {
      type: String,
      required: true,
      default: "",
    },
  },

  emits: ["close"],

  setup(props, { emit }) {
    const localStorageKey = "mygen_estimate";
    const router = useRouter();
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });

    const formMode = ref(props.propsFormMode);
    const editId = ref(props.propsEditId);

    const isChange = computed(() => {
      return formMode.value === "change";
    });
    const isEstimate = computed(() => {
      return formMode.value === "estimate";
    });

    const cardTitle = computed(() => {
      return isEstimate.value
        ? "簡単見積"
        : isChange.value
        ? "契約変更申込"
        : "新規申込";
    });

    const stepFirstValue = 1;
    const stepSecondValue = 2;
    const stepThirdValue = 3;
    const stepFourthValue = computed(() => {
      return isChange.value ? 99 : 4;
    });
    const stepFifthValue = computed(() => {
      return isChange.value ? 4 : 5;
    });
    const stepSixthValue = computed(() => {
      return isChange.value ? 5 : 6;
    });

    const isStepFourth = computed(() => {
      return isChange.value ? false : true;
    });
    const isStepSixth = computed(() => {
      return isEstimate.value ? false : true;
    });

    const stepFirstTitle = ref("契約プラン");
    const stepSecondTitle = ref("アカウント数");
    const stepThirdTitle = ref("契約オプション");
    const stepFourthTitle = computed(() => {
      return isEstimate.value
        ? "GEN COIN & クーポン & 宛名"
        : "GEN COIN & クーポン";
    });
    const stepFourthComment = computed(() => {
      const coinQuantity =
        forms.coinQuantity.value !== null &&
        forms.coinQuantity.value !== undefined
          ? forms.coinQuantity.value
          : 0;
      let res = coinQuantity + "coin";
      if (forms.couponName !== "") {
        res += " & " + forms.couponName;
      }
      return res;
    });
    const stepFourthSiteName = computed(() => {
      return forms.siteName.value;
    });
    const stepFourthEstimateName = computed(() => {
      return forms.estimateName.value;
    });
    const stepFourthEstimateAddress = computed(() => {
      return forms.estimateAddress.value;
    });
    const stepFifthTitle = computed(() => {
      return isEstimate.value ? "見積内容確認" : "申込内容確認";
    });
    const stepSixthTitle = ref("申込完了");

    const formFirst = ref(false);
    const formSecond = ref(false);
    const formThird = ref(false);
    const formFourth = ref(false);
    const formFifth = ref(false);

    const stepCount = ref(1);
    const rates = ref({});
    const contractFields = ref({});
    const plans = ref({});
    const options = ref({});
    const optionsDefault = ref({});
    const optionsAccountAdd = ref({});
    const optionsNormalAdd = ref({});
    const isOptionsAccountAdd = computed(() => {
      return Object.keys(optionsAccountAdd.value).length > 0 ? true : false;
    });
    const isOptionsNormalAdd = computed(() => {
      return Object.keys(optionsNormalAdd.value).length > 0 ? true : false;
    });
    const structure = ref({});
    const coupons = ref({});
    const beforeAccountQuantity = ref(0);

    const togglePlan = ref("");
    const togglePlanSelect = ref("");
    const toggleOption = ref([]);

    // クーポンコードチェックルール
    const couponCheckRules = (v) => {
      if (v === undefined || v === null || v === "") return true;
      return v in coupons.value || "存在しないクーポンコードです";
    };

    const tempAccountPriceComment = isChange.value
      ? "アカウント数を変更したい場合は、変更後のアカウント数を指定してください。（例：現在の５アカウントから３アカウント増やす場合は８を指定。現在の５アカウントから１アカウント減らす場合は４を指定。）"
      : "標準アカウントの必要数を指定してください。";
    const forms = reactive({
      planComment: ["申し込むプランを選択してください。"],
      accountPrice: "",
      accountPriceComment: [
        tempAccountPriceComment,
        "各オプション（＋ONEやトークボードユーザーなど）のアカウント数は［③ オプション選択］で指定できます。",
      ],
      accountQuantity: {
        type: "number",
        value: "",
        label: "アカウント数",
        rules: [numericIntegerRules],
        min: 1,
      },
      accountChangeComment: [
        "アカウント数を減らす場合は、削除するアカウント名を入力してください。",
      ],
      accountOrderComment: {
        type: "textarea",
        value: "",
        label: "削除アカウント",
        rules: [],
        min: 1,
      },
      optionComment: [
        "追加するオプションを選択してください。",
        "数量指定の必要なオプションは必要数を指定してください。" +
          (isChange.value
            ? "（オプションの数量を変更したい場合は、変更後の数量を指定してください。）"
            : ""),
      ],
      optionChangeComment: [
        "オプションのアカウント数を減らす場合は、削除するアカウント名を入力してください。",
      ],
      optionOrderComment: {
        type: "textarea",
        value: "",
        label: "削除アカウント",
        rules: [],
        min: 1,
      },
      coinRate: "",
      coinRateComment: "最低300コイン / 50コイン単位で購入可です。",
      coinQuantity: {
        type: "number",
        value: isChange.value ? 0 : 300,
        label: "コイン",
        rules: [numericIntegerOrZeroRules],
        min: 0,
      },
      coupon: "クーポン利用",
      couponComment: "クーポンご利用の場合はクーポンコードを入力してください。",
      couponCode: {
        type: "text",
        value: "",
        label: "クーポンコード",
        rules: [couponCheckRules],
      },
      couponId: "",
      couponName: "",
      siteNameTitle: "サイト識別名",
      siteName: {
        type: "text",
        value: "自社用サイト",
        label: "サイト識別名",
        // default: "自社用サイト",
        rules: [requiredRules, charLength200Rules],
      },
      siteNameComment: [
        "契約するサービスサイトの識別名称を指定できます。",
        "指定した名称は契約中のサービス一覧に表示されます。",
      ],
      estimateTitle: "見積先",
      estimateName: {
        type: "text",
        value: "",
        label: "宛名",
        rules: [requiredRules, charLength200Rules],
      },
      estimateAddress: {
        type: "text",
        value: "",
        label: "住所",
        rules: [charLength200Rules],
      },
      estimateComment: ["見積先の宛名と住所を指定できます。"],
      estimateRemarksTitle: "備考",
      estimateRemarks: {
        type: "text",
        value: "",
        label: "備考",
        rules: [],
      },
      // 見積会員ID
      customerCode: "",
    });
    const totals = reactive({
      totalPlanCharge: {
        label: "プラン料金",
        value: 0,
        isDark: false,
        isShow: true,
      },
      optionTotal: {
        label: "オプション料金",
        value: 0,
        isDark: false,
        isShow: true,
      },
      supportCharge: {
        label: "サポート料金",
        value: 0,
        isDark: false,
        isShow: true,
      },
      totalCoinCharge: {
        label: "GEN-COIN",
        value: 0,
        isDark: false,
        isShow: true,
      },
      monthlyCharge: {
        label: "月額料金",
        value: 0,
        includingTaxValue: 0,
        isDark: true,
        isShow: true,
      },
      firstCharge: {
        label: "初期費用",
        value: 0,
        includingTaxValue: 0,
        isDark: true,
        isShow: false,
      },
      changeCharge: {
        label: "変更費用",
        value: 0,
        includingTaxValue: 0,
        isDark: true,
        isShow: false,
      },
    });
    const checkPurchaseTerms = reactive({
      value: false,
      label: "利用規約に同意する（利用規約を確認するとチェックできます）",
      rules: [requiredCheckboxRules],
    });
    const orderData = ref({});

    const isTosDownload = ref(false);
    const isOrderCalc = ref(false);
    const progress = ref(false);
    const progressOrderCalc = ref(false);
    const formKey = ref(createKey());

    // Step1：契約プラン
    const changePlan = () => {
      const data = plans.value[togglePlan.value];
      forms.accountPrice =
        "1user @" +
        convertNumComma(data["account_price"]) +
        "円（税込" +
        convertNumComma(data["account_price_including_tax"]) +
        "円）";
      togglePlanSelect.value = data["plan_name"];

      // オプション設定
      toggleOption.value = []; // オプション選択の初期化
      optionsDefault.value = {};
      optionsAccountAdd.value = {};
      optionsNormalAdd.value = {};
      const tempStructure = structure.value[togglePlan.value];
      for (const key in options.value) {
        for (const tempKey in tempStructure) {
          if (
            tempStructure[tempKey]["option_id"] === key &&
            tempStructure[tempKey]["structure_class"] === 10
          ) {
            optionsDefault.value[key] = options.value[key];
          } else if (
            tempStructure[tempKey]["option_id"] === key &&
            tempStructure[tempKey]["structure_class"] === 20 &&
            options.value[key]["option_class"] === 10
          ) {
            optionsAccountAdd.value[key] = options.value[key];
          } else if (
            tempStructure[tempKey]["option_id"] === key &&
            tempStructure[tempKey]["structure_class"] === 20
          ) {
            optionsNormalAdd.value[key] = options.value[key];
          }
        }
      }
    };

    // Step3：契約オプション
    const changeToggleBtn = (key) => {
      // トグルボタンアクション
      if (toggleOption.value.includes(key)) {
        options.value[key]["optionQuantity"]["disabled"] = true;
        options.value[key]["optionQuantity"]["value"] = "";
      } else {
        options.value[key]["optionQuantity"]["disabled"] = false;
      }
    };
    const getToggleOptionData = () => {
      const selectOption = {};
      for (const key of toggleOption.value) {
        if (optionsDefault.value[key]) {
          continue;
        }
        selectOption[key] = options.value[key];
      }
      return selectOption;
    };
    const checkStepThird = () => {
      for (const key of toggleOption.value) {
        const data = options.value[key];
        if (data.optionQuantity.display === 1) {
          if (
            !checkRequired(
              data["option_name"] + "の" + data.optionQuantity.label,
              data.optionQuantity.value
            )
          ) {
            return false;
          }
          // form バリデーション
          const validResult = formThird.value.validate();
          if (!validResult) {
            return false;
          }
        }
      }
      return true;
    };

    // Step4：COIN & クーポン
    const checkStepFourth = () => {
      // GEN-COINチェック
      const validResult = formFourth.value.validate();
      if (!validResult) {
        return false;
      }
      // クーポンチェック
      forms.couponId = "";
      if (forms.couponCode.value !== "") {
        if (coupons.value[forms.couponCode.value] === undefined) {
          store.dispatch("message/setErrorMessage", {
            message: "指定されたクーポンが存在しません",
          });
          return false;
        } else {
          forms.couponId = coupons.value[forms.couponCode.value].id;
          forms.couponName = coupons.value[forms.couponCode.value].coupon_name;
        }
      }
      return true;
    };

    // 申込情報取得
    const loadOrderData = () => {
      // ordercalcフラグ
      isOrderCalc.value = true;
      const optionsQtys = {};
      for (const key in options.value) {
        optionsQtys[key] = options.value[key]["optionQuantity"];
      }
      // api:申込金額計算情報取得
      // 当初getを使用していたがGETパラメーターが長すぎるとnginxの設定を変更しなければいけないためPOSTで取得する
      const data = {
        order_type: isChange.value ? 20 : 10,
        plan_id: togglePlan.value,
        account_quantity: forms.accountQuantity.value,
        order_account_comment: forms.accountOrderComment.value,
        option_id: toggleOption.value,
        option_quantitys: JSON.stringify(optionsQtys),
        order_option_comment: forms.optionOrderComment.value,
        coin_quantity:
          forms.coinQuantity.value !== null &&
          forms.coinQuantity.value !== undefined
            ? forms.coinQuantity.value
            : 0,
        coupon_code: forms.couponCode.value,
        base_contract_plan_id: editId.value,
        site_name: forms.siteName.value,
      };
      api({
        method: "post",
        url: "/ordercalc/",
        data: data,
      })
        .then((response) => {
          const res = response.data;
          orderData.value = res;
          // 見積印刷用データ作成
          const estimateData = {
            estimate_name: forms.estimateName.value,
            estimate_address: forms.estimateAddress.value,
            estimate_remarks: forms.estimateRemarks.value,
            customer_code: forms.customerCode,
          };
          // ローカルストレージに計算結果と見積情報を保存
          localStorage.removeItem(localStorageKey);
          localStorage.setItem(
            localStorageKey,
            JSON.stringify(Object.assign(res, estimateData))
          );
          // 料金ラベルに情報表示
          totals.totalPlanCharge.value = res.total_plan_charge;
          totals.optionTotal.value = res.option_total;
          totals.supportCharge.value = res.support_charge;
          totals.totalCoinCharge.value = res.total_coin_charge;
          totals.monthlyCharge.value = res.monthly_charge;
          totals.monthlyCharge.includingTaxValue =
            res.monthly_charge_including_tax;
          totals.firstCharge.value = res.first_charge;
          totals.firstCharge.includingTaxValue = res.first_charge_including_tax;
          totals.changeCharge.value = res.change_charge;
          totals.changeCharge.includingTaxValue =
            res.change_charge_including_tax;
        })
        .then(() => {
          // 表示待ちの OverlayProgress コントロール非表示
          // loadData内でセットされた progress を解除する
          progress.value = false;
          // ordercalcフラグ
          isOrderCalc.value = false;
        });
    };

    /**
     * 情報取得
     */
    const loadData = () => {
      // 表示待ちの OverlayProgress コントロール表示
      // 非表示は計算情報が表示される loadOrderData内で行っている
      progress.value = true;

      let apiAddress = "/ordersetting/";
      if (isChange.value) {
        apiAddress += "?change=true&id=" + editId.value;
      }
      // api:設定情報取得
      api({
        method: "get",
        url: apiAddress,
      })
        .then((response) => {
          const res = response.data;
          const settingData = res["order_setting_data"];
          const tempBaseData = res["base_data"];

          // レート設定
          rates.value = {};
          if (settingData) {
            rates.value["order_tax_rate"] = settingData["tax_rate"];
            rates.value["coin_rate_including_tax"] =
              settingData["coin_rate_including_tax"];
            rates.value["order_support_rate"] = settingData["support_rate"];
            forms.coinRate =
              "GEN-COIN @" +
              convertNumComma(settingData["coin_rate"]) +
              "円（税込" +
              convertNumComma(settingData["coin_rate_including_tax"]) +
              "円）";
          }

          // 契約プラン設定
          plans.value = settingData["plan_data"];

          // 契約オプション設定
          options.value = settingData["option_data"];
          for (const key in options.value) {
            const optionQuantity = {
              display: options.value[key]["quantity_display"],
              type:
                options.value[key]["quantity_display"] === 1 ? "number" : "",
              value: "",
              label: options.value[key]["quantity_label"],
              rules:
                options.value[key]["quantity_display"] === 1
                  ? [numericIntegerOrBlankRules]
                  : [],
              min: 0,
              disabled: true,
            };
            options.value[key]["optionQuantity"] = optionQuantity;
          }

          // 構成設定
          structure.value = settingData["structure_data"];

          // クーポン設定
          coupons.value = settingData["coupon_data"];

          // 既存データのロード設定
          if (isChange.value && tempBaseData) {
            contractFields.value = tempBaseData["contract"];
            togglePlan.value = tempBaseData["plan"];
            changePlan();
            forms.accountQuantity.value = tempBaseData["account_quantity"];
            beforeAccountQuantity.value = tempBaseData["account_quantity"];
            const baseOption = [];
            for (const key in tempBaseData["contract_option"]) {
              const optionData = tempBaseData["contract_option"][key];
              baseOption.push(optionData["option"]);
              if (
                options.value[optionData["option"]]["optionQuantity"][
                  "display"
                ] === 1
              ) {
                options.value[optionData["option"]]["optionQuantity"]["value"] =
                  optionData["option_quantity"];
                options.value[optionData["option"]]["optionQuantity"][
                  "disabled"
                ] = false;
              }
            }
            toggleOption.value = baseOption;

            totals["changeCharge"]["isShow"] = true;
          } else {
            totals["firstCharge"]["isShow"] = true;
          }
        })
        .then(() => {
          // 申込情報取得
          loadOrderData();
        })
        .then(() => {
          api({
            method: "get",
            url: "/customer/?id=" + customerId.value,
          }).then((response) => {
            const res = response.data[0];
            let zipCode = "";
            if (res["zip_code"]) {
              zipCode =
                "〒" +
                res["zip_code"].substr(0, 3) +
                "-" +
                res["zip_code"].substr(3);
            }
            // 見積先宛名
            forms.estimateName.value =
              res["invoice_customer_name"] +
              "  " +
              res["invoice_person_in_charge"] +
              " 様";
            // 見積先住所
            forms.estimateAddress.value =
              zipCode +
              "  " +
              res["invoice_address_1"] +
              " " +
              res["invoice_address_2"];
            // 見積会員ID
            forms.customerCode = res["customer_code"];
          });
        });
    };

    /**
     * api:データ登録
     */
    const saveData = () => {
      progress.value = true;
      // apiで渡すデータを生成
      const optionsQtys = {};
      for (const key in options.value) {
        optionsQtys[key] = options.value[key]["optionQuantity"];
      }
      const coinQuantity =
        forms.coinQuantity.value === undefined ? 0 : forms.coinQuantity.value;

      const date = new Date();
      const nextDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      const startDate =
        nextDate.getFullYear() + "-" + (nextDate.getMonth() + 1) + "-1";

      const data = {
        order_type: isChange.value ? 20 : 10,
        customer_id: store.state.auth.customerId,
        contract_fields: contractFields.value,
        plan_id: togglePlan.value,
        account_quantity: forms.accountQuantity.value,
        order_account_comment: forms.accountOrderComment.value,
        option_id: toggleOption.value,
        option_quantitys: JSON.stringify(optionsQtys),
        order_option_comment: forms.optionOrderComment.value,
        coin_quantity: coinQuantity,
        coupon_code: forms.couponCode.value,
        base_contract_plan_id: editId.value,
        start_date: startDate,
        site_name: forms.siteName.value,
      };
      // 登録
      api({
        method: "post",
        url: "/contractplanbase/",
        data: data,
      }).then(
        () => {
          store.dispatch("message/setInfoMessage", {
            message: "契約の申込が完了しました。",
          });
          stepCount.value++;
          progress.value = false;
        },
        (reason) => {
          console.error(reason); // Error!
          progress.value = false;
        }
      );
    };

    // 利用規約ダウンロード
    const downloadTos = () => {
      isTosDownload.value = true;
    };

    // ボタン1
    const submitBtnFirst = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formFirst.value.validate();
      if (validResult) {
        formKey.value = createKey();
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン2
    const submitBtnSecond = () => {
      store.dispatch("message/clearMessages");
      // form バリデーション
      const validResult = formSecond.value.validate();
      // 変更申込時の削除ユーザーチェック
      if (
        isChange.value &&
        forms.accountQuantity.value < beforeAccountQuantity.value &&
        forms.accountOrderComment.value === ""
      ) {
        store.dispatch("message/setErrorMessage", {
          message: "削除アカウントが入力されていません",
        });
        return false;
      }
      if (validResult) {
        formKey.value = createKey();
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン3
    const submitBtnThird = () => {
      store.dispatch("message/clearMessages");
      if (checkStepThird()) {
        formKey.value = createKey();
        // 変更申込時の変更チェック
        if (isChange.value && orderData.value["order_change"] !== true) {
          store.dispatch("message/setErrorMessage", {
            message: "契約内容が変更されていません",
          });
          return false;
        }
        store.dispatch("message/clearMessages");
        stepCount.value++;
      }
    };
    // ボタン4
    const submitBtnFourth = () => {
      store.dispatch("message/clearMessages");
      // バリデーション
      if (checkStepFourth()) {
        // loadOrderData API終了チェック
        let timer = null;
        const checkOrderCalc = new Promise((resolve, reject) => {
          let count = 0;
          // [0.2sec]のsetInterval
          timer = setInterval(() => {
            count++;
            if (!isOrderCalc.value) {
              clearInterval(timer);
              resolve(true);
            }
            // [20sec]でエラー判定
            if (count >= 100) {
              clearInterval(timer);
              console.log("Order Calc Error!!");
              reject(true);
            }
          }, 200);
        });
        progressOrderCalc.value = true;
        checkOrderCalc
          .then(() => {
            progressOrderCalc.value = false;
          })
          .then(() => {
            // 計算終了未遂
            if (isOrderCalc.value) {
              return false;
            }
            // Next Step
            formKey.value = createKey();
            store.dispatch("message/clearMessages");
            stepCount.value++;
          })
          .catch(() => {
            // エラーハンドリング
            store.dispatch("message/setErrorMessage", {
              message: "契約金額計算のエラーです",
            });
            progressOrderCalc.value = false;
            return false;
          });
      }
    };
    // ボタン5
    const submitBtnFifth = () => {
      if (isChange.value && orderData.value["order_change"] !== true) {
        store.dispatch("message/setErrorMessage", {
          message: "契約内容が変更されていません",
        });
        return false;
      }
      const validResult = formFifth.value.validate();
      if (validResult) {
        saveData();
      }
    };
    // ボタン5
    const submitBtn5 = () => {
      router.go({ path: "/", force: true });
    };
    // 戻るボタン
    const returnBtn = () => {
      store.dispatch("message/clearMessages");
      if (stepCount.value > 1) {
        stepCount.value--;
      }
    };
    // 閉じるボタン
    const clickClose = () => {
      // ダイアログ
      emit("close");
    };
    // 帳票発行
    const clickReport = () => {
      return;
    };

    onMounted(() => {
      // ローカルストレージ情報の削除
      localStorage.removeItem(localStorageKey);
      store.dispatch("message/clearMessages");
    });

    // データ取得
    loadData();

    return {
      localStorageKey,
      convertNumComma,
      formMode,
      editId,

      cardTitle,
      stepFirstValue,
      stepSecondValue,
      stepThirdValue,
      stepFourthValue,
      stepFifthValue,
      stepSixthValue,

      isStepFourth,
      isStepSixth,

      stepFirstTitle,
      stepSecondTitle,
      stepThirdTitle,
      stepFourthTitle,
      stepFourthComment,
      stepFourthSiteName,
      stepFourthEstimateName,
      stepFourthEstimateAddress,
      stepFifthTitle,
      stepSixthTitle,

      isChange,
      isEstimate,
      formFirst,
      formSecond,
      formThird,
      formFourth,
      formFifth,

      stepCount,
      rates,
      contractFields,
      plans,
      options,
      optionsDefault,
      optionsAccountAdd,
      optionsNormalAdd,
      isOptionsAccountAdd,
      isOptionsNormalAdd,
      structure,
      coupons,

      togglePlan,
      togglePlanSelect,
      toggleOption,

      forms,
      totals,
      checkPurchaseTerms,
      orderData,

      isTosDownload,
      isOrderCalc,
      progress,
      progressOrderCalc,
      formKey,

      changePlan,
      changeToggleBtn,
      getToggleOptionData,

      loadOrderData,

      downloadTos,
      submitBtnFirst,
      submitBtnSecond,
      submitBtnThird,
      submitBtnFourth,
      submitBtnFifth,
      submitBtn5,
      returnBtn,
      clickClose,
      clickReport,
    };
  },
});
</script>
